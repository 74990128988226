import "./Offers.scss";

export default function Offers() {
  return (
    <div className="offers" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h2>دليل الخصومات على الكورسات والدورات التدريبية</h2>
      <p style={{ lineHeight: "2.5" }}>
        دليل لأحدث العروض والخصومات المقدمة على الكورسات والبرامج التدريبية
        المقدمة من أكاديمية المهارات الدولية للتدريب، عروض حصرية وخصومات غير
        مسبوقة ممتدة لفترة محدودة. اغتنم الفرصة في الحال!
      </p>
      <p>
        <strong style={{ fontSize: "17px" }}>
          عرض حصري للشركات والمجموعات:
        </strong>
        <br />
        أكاديمية المهارات الدولية تقدم عرضًا خاصًا للشركات والمجموعات عند
        التسجيل والاشتراك في أي برنامج تدريبي.
        <br />
        كلما زاد عدد المتدربين في المجموعة، كانت نسبة الخصم أكبر:
      </p>
      <ul style={{ lineHeight: "2.5" }}>
        <li>
          <strong>عدد المتدربين 2:</strong> نسبة الخصم{" "}
          <strong style={{ fontSize: "larger" }}>10%</strong>
        </li>
        <li>
          <strong>عدد المتدربين 3:</strong> نسبة الخصم{" "}
          <strong style={{ fontSize: "larger" }}>15%</strong>
        </li>
        <li>
          <strong>عدد المتدربين 4:</strong> نسبة الخصم{" "}
          <strong style={{ fontSize: "larger" }}>20%</strong>
        </li>
        <li>
          <strong>عدد المتدربين 5+:</strong> نسبة الخصم{" "}
          <strong style={{ fontSize: "larger" }}>25%</strong>
        </li>
      </ul>

      <p>
        <strong style={{ fontSize: "17px" }}>
          عروض خاصة للمؤسسات الخيرية والجمعيات غير الهادفة للربح:
        </strong>
        <br />
        عرض خاص ودائم لمؤسسات العمل الخيري في إطار حرص أكاديمية المهارات الدولية
        للتدريب على القيام بواجبات المسؤولية الاجتماعية ودورها تجاه المجتمع.
        تتشرف الأكاديمية بتوفير خصم مباشر 15% على رسوم التسجيل والاشتراك في جميع
        الدورات والبرامج التدريبية، سواء للأفراد العاملين في تلك المؤسسات أو
        المجموعات، وسواء كان التدريب في مقر الأكاديمية أو في مقر المؤسسة.
      </p>
    </div>
  );
}
