import React, { useEffect, useState, useRef } from "react";
import "./Header.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import hamburger from "../../images/menu2.png";
import userIcon from "../../images/user.png";
import cartIcon from "../../images/shopping-bag.png";
import logoutIcon from "../../images/logout.png";
import searchIcon from "../../images/icons8-search-30.png";
import useAuth from "../../hooks/useAuth";
import LogoImage from "../../images/Layer 0.png";
import axios from "axios";
import WhatsAppIcon from "../../images/3225179_app_logo_media_popular_social_icon.svg";

const Header = () => {
  const { user, logOut } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [infoDropdownOpen, setInfoDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [whatsappLink, setWhatsappLink] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const dropdownRef = useRef(null);
  const infoDropdownRef = useRef(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      infoDropdownRef.current &&
      !infoDropdownRef.current.contains(event.target)
    ) {
      setInfoDropdownOpen(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/website_settings/"
        );
        const data = await response.json();
        if (data.results.length > 0) {
          setWhatsappLink(data.results[0].whatsapp);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    logOut();
    history.push("/");
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/categories/`
        );
        const data = await response.json();
        setCategories(data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/courses/?search=${searchQuery}`
      );
      const data = await response.json();
      setResults(data.results);
    }
  };

  const handleCourseClick = (courseId) => {
    history.push(`/course/${courseId}`);
  };

  const handleCategoryClick = async (slug, categoryId) => {
    setSelectedCategoryId(categoryId);
    await fetchCourses(categoryId);
    history.push(`/category/${slug}`);
    setDropdownOpen(false);
    setMenuOpen(false);
  };

  const fetchCourses = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://admin.internationalskillsacademy.com/api/courses/?categories__id=${categoryId}`
      );
      setCourses(response.data.results);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <nav
        className="navbar navbar-expand-md fixed-top"
        style={{ height: "100px" }}
      >
        <div className="container" style={{ maxWidth: "95%" }}>
          <Link className="link" to="/home">
            <img className="logo" src={LogoImage} alt="Logo" />
          </Link>
          <div
            className={`navbar-collapse collapse ${menuOpen ? "show" : ""}`}
            ref={menuRef}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="link"
                  to="/home"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  الصفحة الرئيسية{" "}
                </Link>
              </li>
              <li className="nav-item dropdown" ref={dropdownRef}>
                <span
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="link dropdown-toggle"
                >
                  أقسام التدريب
                </span>
                <ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                  {categories.map((category) => (
                    <li
                      style={{ cursor: "pointer" }}
                      key={category.id}
                      onClick={() => {
                        handleCategoryClick(category.slug, category.id);
                        setDropdownOpen(false);
                      }}
                    >
                      <span className="dropdown-item">{category.title}</span>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="link"
                  to="/video-library"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  مكتبة الفيديو{" "}
                </Link>
              </li>
              <li className="nav-item dropdown" ref={infoDropdownRef}>
                <span
                  onClick={() => setInfoDropdownOpen(!infoDropdownOpen)}
                  className="link dropdown-toggle"
                >
                  من نحن
                </span>
                <ul
                  className={`dropdown-menu ${infoDropdownOpen ? "show" : ""}`}
                >
                  <li className="nav-item">
                    <Link
                      className="dropdown-item"
                      to="/about"
                      onClick={() => {
                        setInfoDropdownOpen(false);
                        setMenuOpen(false);
                      }}
                    >
                      من نحن
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="dropdown-item"
                      to="/contact"
                      onClick={() => {
                        setInfoDropdownOpen(false);
                        setMenuOpen(false);
                      }}
                    >
                      التواصل معنا
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="link"
                  to="/library"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  المكتبة و الأبحاث{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link"
                  to="/Consulting-and-studies"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  الاستشارات و الدراسات{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link"
                  to="/live-courses"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  الكورسات المباشرة{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link"
                  to="/join-us"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  انضم إلينا{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link"
                  to="/verification-section"
                  onClick={() => setMenuOpen(false)}
                >
                  {" "}
                  التحقق من الشهادة{" "}
                </Link>
              </li>
            </ul>
          </div>
          <ul className="user-cart">
            {user ? (
              <li className="nav-item" onClick={handleLogout}>
                <img src={logoutIcon} alt="Logout" className="link" />
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  className="link"
                  to="/login"
                  onClick={() => setMenuOpen(false)}
                >
                  <img src={userIcon} alt="Login" />
                </Link>
              </li>
            )}
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <img className="hamburger" src={hamburger} alt="Menu" />
          </button>
        </div>
      </nav>

      <div
        className="whatsapp-icon"
        onClick={() => window.open(`https://wa.me/${whatsappLink}`, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <img src={WhatsAppIcon} alt="WhatsApp" />
      </div>
    </>
  );
};

export default Header;
