import React, { useEffect, useState } from "react";
import "./CHL-Page.scss";

export default function CHLPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/pages/"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok" + response.statusText);
        }
        const result = await response.json();
        setData(result.results[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>جارٍ التحميل...</p>;
  }

  if (error) {
    return <p>حدث خطأ: {error.message}</p>;
  }

  return (
    <>
      <img
        style={{ marginTop: "200px", marginBottom: "100px" }}
        className="img"
        src={data.image}
        alt={data.title}
      />
      <h1 className="chl-h1">{data.title}</h1>
      {/* <p className="chl-text">{data.body}</p> */}
      <p dangerouslySetInnerHTML={{ __html: data.body }} />
    </>
  );
}
