import Regulations from "../Regulations/Regulations";
import "./Rules.scss";

export default function Rules() {
  return (
    <div className="rules" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h2>القواعد التنظيمية لحضور الدورات والبرامج التدريبية</h2>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        القواعد التنظيمية الخاصة بالمجموعات التدريبية:
      </h4>
      <ul style={{ lineHeight: "2" }}>
        <li>
          التفاعل مع المحاضر في المناقشات والاسئلة والاجابة شرط هام من شروط
          احتساب الحضور او الغياب وعدم تفاعلك يعني أنك غير موجود داخل المحاضرة.
        </li>
        <li>
          يشترط لإصدار الشهادة التدريبية ألا تقل نسبة الحضور عن 70% من اجمالي
          عدد الساعات التدريبية في البرنامج.
        </li>
        <li>
          المحاضرات المسجلة يمكنك مشاهدتها والاستفادة منها في مراجعة المحاضرة
          لكنها لا تؤثر على نسبة الحضور إذ أن الحضور يتم احتسابه في المحاضرات
          المباشرة Live فقط.
        </li>
        <li>
          في الدورات التي تشمل مشروعات يجب على المتدرب الالتزام بتقديم المشروع
          في الوقت الذي يحدده المحاضر.
        </li>
        <li>
          جدول المحاضرات الذي تم اقراره غير قابل للتعديل طوال فترة البرنامج الا
          في الظروف القهرية وبموافقة ادارة الاكاديمية ويشترط للتعديل موافقة كافة
          المتدربين في المجموعة على هذا التعديل وان يكون هذا التعديل مقبولاً من
          جانب المحاضر ولا يتعارض مع جداول أخرى.
        </li>
        <li>
          يمكن للمتدرب مراجعة بياناته من خلال صفحته ويمكنه اجراء تعديل على الاسم
          بالصورة الصحيحة التي يرغب في ظهورها في الشهادة التدريبية.
        </li>
      </ul>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        القواعد التنظيمية الخاصة بالدورات البرايفت:
      </h4>
      <ul style={{ lineHeight: "2" }}>
        <li>
          التفاعل مع المحاضر في المناقشات والاسئلة والاجابة شرط هام من شروط
          احتساب الحضور او الغياب وعدم تفاعلك يعني أنك غير موجود داخل المحاضرة.
        </li>
        <li>
          يشترط لإصدار الشهادة التدريبية ألا تقل نسبة الحضور عن 70% من اجمالي
          عدد الساعات التدريبية في البرنامج.
        </li>
        <li>
          المحاضرات المسجلة يمكنك مشاهدتها والاستفادة منها في مراجعة المحاضرة
          لكنها لا تؤثر على نسبة الحضور إذ أن الحضور يتم احتسابه في المحاضرات
          المباشرة Live فقط.
        </li>
        <li>
          يمكنك تعديل مواعيد محاضرة أو إلغاؤها أو ترحيلها بشرطين:
          <ul>
            <li> أن يتم الابلاغ قبل موعد المحاضرة بمدة لا تقل عن 24 ساعة.</li>
            <li>
              أن يكون هذا التعديل مقبولا من جانب المحاضر ولا يتعارض مع جداول
              أخرى.
            </li>
          </ul>
        </li>
        <li>
          في حالة عدم حضور المتدرب للمحاضرة حسب الجدول دون إخطار مسبق فلا يتم
          تعويض المحاضرة ويتم احتساب المحاضرة على المتدرب (غياب) ويعاد توزيع
          المادة التدريبية على المحاضرات المتبقية.
        </li>
        <li>
          في الدورات التي تشمل مشروعات يجب على المتدرب الالتزام بتقديم المشروع
          في الوقت الذي يحدده المحاضر.
        </li>
        <li>
          يمكن للمتدرب مراجعة بياناته من خلال صفحته ويمكنه اجراء تعديل على الاسم
          بالصورة الصحيحة التي يرغب في ظهورها في الشهادة التدريبية.
        </li>
        <li>
          المحاضرات التدريبية تتم وفقاً للمواعيد المقررة في الجدول التدريبي ولا
          يسمح للمتدرب التأخر عن موعد المحاضرة بمدة تتجاوز 30 دقيقة بدون اخطار
          المنسق أو 60 دقيقة بإخطار منسق البرنامج علماً بأن المحاضرة ستنتهي في
          وقتها المحدد ولا يتم تعويض الوقت المستقطع في نهاية المحاضرة.
        </li>
      </ul>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        القواعد التنظيمية لحضور الدورات التدريبية لموظفي الشركات:
      </h4>
      <ul style={{ lineHeight: "2" }}>
        <li>
          يجب تسليم بيانات المجموعة التدريبية من طرف مسؤول التدريب الى قسم
          التنسيق بالأكاديمية قبل بداية البرنامج بمدة كافية لإدخال بياناتهم
          وانشاء الحسابات واتمام انضمام الموظفين للبرنامج التدريبي.
        </li>
        <li>
          استيفاء نسبة حضور 70% للموظف هي إلزامية لاستصدار الشهادة التدريبية
          الخاصة بهذا الموظف، وإلا لن يتم اصدار شهادة تدريبية للموظف الذي لم يصل
          الى تلك النسبة.
        </li>
        <li>
          يحق لمسؤول التدريب الاطلاع على كشف الحضور والغياب الخاص بالمجموعة،
          كذلك له الحق في الاطلاع على المحاضرات المسجلة للوقوف على مدى تفاعل
          الموظفين اثناء العملية التدريبية، ويمكنه أيضا تحميل المادة التدريبية
          للاطلاع عليها.
        </li>
        <li>
          يحق لمسؤول التدريب الحصول على تقرير وافي من المُدرب/المُحاضر يقوم فيه
          بتقييم المتدربين وتقييم تفاعلهم اثناء البرنامج التدريبي، وكذلك ابداء
          ملاحظاته عن اداء المتدربين.
        </li>
        <li>
          يتم تسليم الشهادات لمسؤول التدريب في نهاية البرنامج، إلا إن طلب هو
          تسليم الشهادات للمتدربين مباشرةً.
        </li>
        <li>
          إذا تم التدريب في مقر العميل، فإنه يتعهد بتوفير المكان الملائم
          والمُجهز لإتمام العملية التدريبية حيث يقوم بتوفير الشاشة ووسائل العرض
          الضرورية وان يكون المكان يتوافق مع اشتراطات تنفيذ البرنامج التدريبي.
        </li>
      </ul>

      <Regulations />
    </div>
  );
}
