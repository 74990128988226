import React, { useEffect, useState } from "react";
import "./Video-Library.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import useAuth from "../../hooks/useAuth";

export default function VideoLibrary() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/video_library/"
        );
        if (!response.ok) {
          throw new Error("حدث خطأ أثناء جلب البيانات.");
        }
        const data = await response.json();

        const purchasedVideos =
          JSON.parse(localStorage.getItem("purchasedVideos")) || [];

        const videosWithPurchaseInfo = data.results.map((video) => ({
          ...video,
          isPurchased: purchasedVideos.includes(video.id),
        }));
        setVideos(videosWithPurchaseInfo);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const onSubmit = (data) => {
    const couponRegex = /^[a-zA-Z0-9-]{36}$/;

    if (!couponRegex.test(couponCode)) {
      alert(" يرجى إدخال كوبون صالح.");
      return;
    }

    const couponData = { coupon_code: couponCode };

    fetch(
      `https://admin.internationalskillsacademy.com/api/video_library/${selectedVideo.id}/enroll/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user.token}`,
        },
        body: JSON.stringify(couponData),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            setVideos((prevVideos) =>
              prevVideos.map((video) =>
                video.id === selectedVideo.id
                  ? { ...video, isPurchased: true }
                  : video
              )
            );
            setShowSuccessMessage(true);
            setCouponCode("");
            setShowCouponModal(false);
          });
        }
        return res.json().then((data) => {
          alert(data.message || "لقد تم استخدام هذا الكوبون بالفعل");
        });
      })
      .catch((error) => {
        console.error("هناك مشكلة:", error);
        setErrorMessage(error.message);
      });
  };

  if (loading) return <div>جاري تحميل الفيديوهات...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="video-library">
      <h2>مكتبة الفيديوهات</h2>
      <div className="container">
        <div className="video-list">
          {videos.map((video) => (
            <div className="video-item" key={video.id}>
              <img
                src={video.image}
                alt={video.title}
                className="video-thumbnail"
              />
              <h4
                style={{
                  marginTop: "10px",
                }}
              >
                {video.title}
              </h4>
              {video.isPurchased ? (
                <>
                  <p>
                    السعر : <span style={{ color: "green" }}>مشتري</span>
                  </p>
                  <video controls>
                    <source src={video.video} type="video/mp4" />
                    متصفحك لا يدعم تشغيل الفيديو.
                  </video>
                </>
              ) : (
                <>
                  <p>السعر: {video.price} SP</p>
                  <p>
                    تاريخ الإنشاء:{" "}
                    {new Date(video.date_created).toLocaleDateString()}
                  </p>
                  <button
                    className="btn-buy"
                    onClick={() => {
                      if (!user) {
                        alert("يرجى تسجيل الدخول لشراء الفيديو.");
                        return;
                      }
                      setSelectedVideo(video);
                      setShowCouponModal(true);
                    }}
                  >
                    اشتري هذا الفيديو
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {showSuccessMessage && (
        <Alert
          style={{ position: "absolute", top: "100px", right: 0, left: 0 }}
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          <Alert.Heading>تم إرسال الكوبون بنجاح!</Alert.Heading>
          <p>لقد تم تطبيق كود الكوبون بنجاح. شكرًا لك على استخدامك.</p>
        </Alert>
      )}

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>أدخل الكوبون</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="أدخل كود الكوبون"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCouponModal(false)}>
            إلغاء
          </Button>
          <Button
            style={{ backgroundColor: "#2f2d52", border: "1px solid #2f2d52" }}
            onClick={onSubmit}
          >
            تأكيد
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
