import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Contact.scss";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [contactImage, setContactImage] = useState("");

  useEffect(() => {
    const fetchContactImage = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/website_settings/`
        );
        const data = response.data.results[0];
        setContactImage(data.contact_image || "");
      } catch (error) {
        console.error("خطأ في جلب الصورة:", error);
      }
    };

    fetchContactImage();
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `https://admin.internationalskillsacademy.com/api/contact/`,
        data
      );

      if (response) {
        setSuccessMessage("تم الإرسال بنجاح!");
        reset();
      } else {
        setErrorMessage("حدث خطأ، يرجى المحاولة مرة أخرى.");
      }
    } catch (error) {
      console.error("خطأ في الإرسال:", error);
      setErrorMessage("حدث خطأ في الإرسال، تأكد من صحة البيانات.");
    }
  };

  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-5 mb-lg-0 pe-lg-5">
            {contactImage && (
              <img className="img-fluid" src={contactImage} alt="" />
            )}
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="px-lg-3">
              <h3>لديك استفسار ؟</h3>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor=""> الاسم : </label>
                    <input
                      className="form-control"
                      {...register("name", { required: true })}
                      placeholder="اسمك"
                    />
                    {errors.name && <span className="error">اسمك مطلوب</span>}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor=""> الايميل : </label>
                    <input
                      className="form-control"
                      {...register("email", { required: true })}
                      placeholder="ايميلك"
                    />
                    {errors.email && (
                      <span className="error">الايميل مطلوب</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="">الرسالة : </label>
                    <textarea
                      className="form-control"
                      {...register("message", { required: true })}
                      placeholder="رسالتك"
                    />
                    {errors.message && (
                      <span className="error">الرسالة مطلوبة</span>
                    )}
                  </div>
                </div>
                <button type="submit" className="btn-black">
                  {" "}
                  ارسل{" "}
                </button>
              </form>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
