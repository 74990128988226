import React, { useEffect, useState } from "react";
import axios from "axios";
import aboutImg from "../../images/about-us.jpeg";
import FeatureSection from "../FeatureSection/FeatureSection";
import "./About.scss";

const About = () => {
  const [aboutData, setAboutData] = useState({ about: "", about_image: null });

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(
          "https://admin.internationalskillsacademy.com/api/website_settings/"
        );
        if (response.data.results.length > 0) {
          const { about, about_image } = response.data.results[0];
          setAboutData({ about, about_image });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAboutData();
  }, []);

  const processAboutText = (text) => {
    return text
      .replace(/\n/g, "<br />")
      .replace(/•/g, "<li>")
      .replace(/([^<]*)<\/li>/g, "$1</li>")
      .replace(/<li>/g, "<ul><li>")
      .replace(/<\/li>(?=<\/li>)/g, "</li>");
  };

  return (
    <>
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ps-md-5 mb-5 mb-md-0">
              {aboutData.about_image ? (
                <img
                  className="img-fluid shadow"
                  src={aboutData.about_image}
                  alt="About"
                />
              ) : (
                // <img
                //   className="img-fluid shadow"
                //   src={aboutImg}
                //   alt="Default"
                // />
                ""
              )}
            </div>
            <div className="col-md-6">
              <div className="about-info">
                <h3>من نحن</h3>
                <p dangerouslySetInnerHTML={{ __html: aboutData.about }} />

                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: processAboutText(aboutData.about),
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureSection />
    </>
  );
};

export default About;
