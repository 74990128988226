import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faClock,
  faBookReader,
  faLanguage,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import "./CourseDetails.scss";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const CourseDetails = () => {
  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState("overview");
  const { handleSubmit, reset } = useForm();
  const { courseId } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const [lessons, setLessons] = useState([]);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // useEffect(() => {
  //   fetch(
  //     `https://admin.internationalskillsacademy.com/api/courses/${courseId}/`
  //   )
  //     .then((res) => {
  //       if (!res.ok) throw new Error("Network response was not ok");
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data) {
  //         setDetails(data);
  //         loadLessons(data.lessonss);

  //         const enrolledCourses =
  //           JSON.parse(localStorage.getItem("enrolledCourses")) || {};
  //         if (enrolledCourses[courseId]) {
  //           setDetails((prevDetails) => ({
  //             ...prevDetails,
  //             is_enrolled: true,
  //           }));
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("There was a problem with the fetch operation:", error);
  //     });
  // }, [courseId]);

  useEffect(() => {
    fetch(
      `https://admin.internationalskillsacademy.com/api/courses/${courseId}/?user=${user.id}`
    )
      .then((res) => {
        if (!res.ok) throw new Error("Network response was not ok");
        return res.json();
      })
      .then((data) => {
        if (data) {
          setDetails(data);
          loadLessons(data.lessonss);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [courseId, user.id]);

  const loadLessons = async (lessonIds) => {
    const articles = await fetch(
      `https://admin.internationalskillsacademy.com/api/uploads/`
    );
    const videos = await fetch(
      `https://admin.internationalskillsacademy.com/api/upload-videos/`
    );

    const articlesData = await articles.json();
    const videosData = await videos.json();

    const combinedLessons = [
      ...articlesData.results.map((article) => ({
        type: "article",
        ...article,
      })),
      ...videosData.results.map((video) => ({ type: "video", ...video })),
    ];

    setLessons(combinedLessons);
  };

  const {
    title = "",
    categories = [],
    lessonss = [],
    price = 0,
    image = "",
    summary = "",
    author = {},
    is_enrolled,
    duration = 0,
    lessons_number = 0,
  } = details;

  useEffect(() => {
    const enrolledCourses =
      JSON.parse(localStorage.getItem("enrolledCourses")) || {};
    if (enrolledCourses[courseId]) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        is_enrolled: true,
      }));
    }
  }, [courseId]);

  // const onSubmit = (data) => {
  //   if (!user) {
  //     alert("يرجى تسجيل الدخول أولاً.");
  //     return;
  //   }

  //   const couponData = { coupon_code: couponCode };

  //   fetch(
  //     `https://admin.internationalskillsacademy.com/api/courses/${courseId}/enroll/`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `token ${user.token}`,
  //       },
  //       body: JSON.stringify(couponData),
  //     }
  //   )
  //     .then((res) => {
  //       if (res.ok) {
  //         const enrolledCourses =
  //           JSON.parse(localStorage.getItem("enrolledCourses")) || {};
  //         enrolledCourses[courseId] = true;
  //         localStorage.setItem(
  //           "enrolledCourses",
  //           JSON.stringify(enrolledCourses)
  //         );

  //         setShowSuccessMessage(true);
  //         reset();
  //         setShowCouponModal(false);
  //         setCouponCode("");
  //         setDetails((prevDetails) => ({
  //           ...prevDetails,
  //           is_enrolled: true,
  //         }));
  //         return res.json();
  //       }
  //       return res.json().then((data) => {
  //         throw new Error(data.message || "فشل في تطبيق الكوبون");
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("هناك مشكلة في عملية السحب:", error);
  //       setErrorMessage(error.message);
  //     });
  // };

  const onSubmit = (data) => {
    if (!user) {
      alert("يرجى تسجيل الدخول أولاً.");
      return;
    }

    const couponFormat = /^[a-zA-Z0-9-]{36}$/;

    if (!couponFormat.test(couponCode)) {
      alert("الكوبون غير صالح");
      return;
    }

    const couponData = { coupon_code: couponCode };

    fetch(
      `https://admin.internationalskillsacademy.com/api/courses/${courseId}/enroll/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user.token}`,
        },
        body: JSON.stringify(couponData),
      }
    )
      .then((res) => {
        if (res.ok) {
          setShowSuccessMessage(true);
          reset();
          setShowCouponModal(false);
          setCouponCode("");
          setDetails((prevDetails) => ({
            ...prevDetails,
            is_enrolled: true,
          }));
          return res.json();
        }
        return res.json().then((data) => {
          throw new Error(data.message || "فشل في تطبيق الكوبون");
        });
      })
      .catch((error) => {
        console.error("هناك مشكلة في عملية السحب:", error);
        setErrorMessage(error.message);
      });
  };

  const handleCouponSubmit = () => {
    setShowCouponModal(true);
  };

  const handleVideoProgress = (lessonId) => (event) => {
    const { currentTime, duration } = event.target;
    const percentage = (currentTime / duration) * 100;

    if (percentage >= 70) {
      sendWatchHistory(user.id, courseId, lessonId);
    }
  };

  const sendWatchHistory = (userId, courseId, lessonId) => {
    fetch(`https://admin.internationalskillsacademy.com/api/watch_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user.token}`,
      },
      body: JSON.stringify({
        user: userId,
        course: courseId,
        lesson: lessonId,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "فشل في تسجيل بيانات المشاهدة");
          });
        }
        console.log("تم تسجيل بيانات المشاهدة بنجاح");
      })
      .catch((error) => {
        console.error("هناك مشكلة في تسجيل بيانات المشاهدة:", error);
        setErrorMessage(error.message);
      });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="indicator-details">
            <h4>تفاصيل الدورة التعليمية</h4>
            <p>{summary}</p>
          </div>
        );
      case "curriculum":
        return (
          <div>
            <h4>المقرر</h4>
            {!user ? (
              <div>
                <p>يجب تسجيل الدخول لعرض محتوى الدروس.</p>
                <p style={{ marginBottom: "70px" }}>
                  قم بتسجيل الدخول لرؤية تفاصيل الدروس. (يمكنك أن ترى فقط
                  العنواين)
                </p>
                <ul>
                  {lessonss.map((lesson) => (
                    <li key={lesson.id}>{lesson.title || "درس غير متوفر"}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                {is_enrolled == true ? (
                  <Accordion>
                    {lessonss.map((lesson, index) => (
                      <Accordion.Item key={lesson.id} eventKey={String(index)}>
                        <Accordion.Header>
                          {lesson.title || "درس غير متوفر"}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>{lesson.short_description || "وصف غير متوفر"}</p>
                          <p>
                            المحتوى: <br />
                            {lesson.long_description || "لا يوجد محتوى متوفر"}
                          </p>
                          {lesson.videos && (
                            <div>
                              <h5>فيديو:</h5>
                              <video
                                width="400"
                                controls
                                onTimeUpdate={handleVideoProgress(lesson.id)}
                              >
                                <source
                                  src={lesson.videos.video}
                                  type="video/mp4"
                                />
                                متصفحك لا يدعم تشغيل الفيديو.
                              </video>
                            </div>
                          )}
                          <br />
                          {lesson.files && lesson.files.length > 0 && (
                            <div>
                              <h5>الملفات:</h5>
                              <ul>
                                {lesson.files.map((file) => (
                                  <li key={file.id}>
                                    <a
                                      href={file.file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {file.title || "ملف"}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <div>
                    <p>يجب التسجيل في الدورة لرؤية محتوى الدروس.</p>
                    <ul>
                      {lessonss.map((lesson) => (
                        <li key={lesson.id}>
                          {lesson.title || "درس غير متوفر"}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        );
      case "instructor":
        return (
          <div>
            <h4>المدرب</h4>
            <p>{author.username || "اسم المدرب غير متوفر"}</p>
            <p>{author.teacherprofile?.bio || "نبذة عن المدرب غير متوفرة"}</p>
          </div>
        );
      case "reviews":
        return (
          <div>
            <h4>المراجعات</h4>
            <p>ليس لدينا مراجعات بعد.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="details-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5 mb-lg-0">
            <div className="detail-box">
              <h3 className="title">{title}</h3>
              <p className="category">{categories[0]?.title || ""}</p>
              <div className="instructor">
                <div className="box">
                  <img src={author.teacherprofile?.image || ""} alt="مدرب" />
                </div>
                <div className="box">
                  <h5> أُنشأ من قبل :</h5>
                  <p className="mb-0">{author.username || ""}</p>
                </div>
              </div>
              <div className="bottom-area">
                <ul className="indicator">
                  <li
                    className={activeTab === "overview" ? "active" : ""}
                    onClick={() => setActiveTab("overview")}
                  >
                    لمحة عن الدورة التعليمية
                  </li>
                  <li
                    className={activeTab === "curriculum" ? "active" : ""}
                    onClick={() => setActiveTab("curriculum")}
                  >
                    المقرر
                  </li>
                  <li
                    className={activeTab === "instructor" ? "active" : ""}
                    onClick={() => setActiveTab("instructor")}
                  >
                    المدرب
                  </li>
                  {/* <li
                    className={activeTab === "reviews" ? "active" : ""}
                    onClick={() => setActiveTab("reviews")}
                  >
                    المراجعات
                  </li> */}
                </ul>
                {renderTabContent()}
              </div>
            </div>
          </div>

          <div className="col-lg-4 ps-lg-5">
            <div className="purchase-course-box">
              <div className="img-box">
                <img className="img-fluid" src={image} alt="" />
              </div>
              <div className="info-box">
                <h4 className="price">{price} SP</h4>
                {user ? (
                  is_enrolled == true ? (
                    <p>أنت بالفعل مشترك في هذه الدورة التعليمية!</p>
                  ) : (
                    <button onClick={handleCouponSubmit} className="btn-black">
                      اشتري هذه الدورة التعليمية
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => history.push("/login")}
                    className="btn-black"
                  >
                    اشتري هذه الدورة التعليمية
                  </button>
                )}
                <ul>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faClock} />
                      المدة
                    </span>
                    <span>{duration}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faBookOpen} />
                      المحاضرات{" "}
                    </span>
                    <span>{lessons_number}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faLanguage} />
                      الفئة
                    </span>
                    <span>{categories[0]?.title || ""}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faCertificate} />
                      وجود شهادة
                    </span>
                    <span>نعم</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSuccessMessage && (
        <Alert
          style={{
            position: "absolute",
            top: "100px",
            right: 0,
            left: 0,
          }}
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          <Alert.Heading>تم إرسال الكوبون بنجاح!</Alert.Heading>
          <p>لقد تم تطبيق كود الكوبون بنجاح. شكرًا لك على استخدامك.</p>
        </Alert>
      )}

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>أدخل الكوبون</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="أدخل كود الكوبون"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCouponModal(false)}>
            إلغاء
          </Button>
          <Button
            style={{ backgroundColor: "#2f2d52", border: "1px solid #2f2d52" }}
            onClick={handleSubmit(onSubmit)}
          >
            تأكيد
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseDetails;
