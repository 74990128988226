import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import paymentGateway from "../../images/online-payment.png";
import Logo from "../../images/Layer 0.png";

const Footer = () => {
  return (
    <div className="footer">
      <div
        className="container"
        style={{ maxWidth: "95%", paddingLeft: "0px" }}
      >
        <div className="row" style={{ paddingLeft: "0px" }}>
          <div className="col-sm-6 col-lg-3">
            <h3 className="logo mb-3">ISA</h3>
            <p className="par-aca" style={{ width: "80%" }}>
              - أكاديمية متخصصة بتنمية المهارات و الموارد البشرية من بداية تكوين
              الشخصية للوصول إلى ممارسة كافة المهارات الحياتية بكفاءة عالية.
              <br />
              - رؤية مختلفة لتطوير الذات و المجتمع .
              <br />
              -مرخصة و معتمدة في الجمهورية العربية السورية <br />
              رقم التسجيل الضريبي : <br /> 110160070530
            </p>
          </div>
          <div className="col-sm-6 col-lg-3">
            <h5> روابط هامة</h5>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="link" to="/offers">
                  العروض والخصومات
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/verification-section">
                  التحقق من الشهادات
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/customer-service">
                  الشكاوى وخدمة العملاء
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/">
                  آراء وتقييمات المتدربين في الاكاديمية
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/rules">
                  القواعد التنظيمية لحضور الدورات التدريبية
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/privacy">
                  الشروط والأحكام | سياسة الخصوصية
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3">
            <h5>التواصل معنا</h5>
            <p
              className="p-cen"
              style={{ direction: "ltr", textAlign: "right" }}
            >
              - الجمهورية العربية السورية
              <br /> طرطوس - المنشية -خلف مبنى المحافظة -<br />
            </p>
            <p
              className="p-cen"
              style={{ direction: "ltr", textAlign: "right" }}
            >
              <a
                style={
                  {
                    // textDecoration: "none",
                    // color: "inherit",
                  }
                }
                href="tel:+963933954480"
              >
                + 963 933 954 480
              </a>
              <br />
              <a
                style={
                  {
                    // textDecoration: "none",
                    // color: "inherit",
                  }
                }
                href="tel:+963988954443"
              >
                + 963 988 954 443
              </a>
              <br />
              <a
                style={
                  {
                    // textDecoration: "none",
                    // color: "inherit",
                  }
                }
                href="tel:+963432326649"
              >
                + 963 432 326 649
              </a>
              <br />
              <a
                style={
                  {
                    // textDecoration: "none",
                    // color: "inherit",
                  }
                }
                href="tel:+963432326650"
              >
                + 963 432 326 650
              </a>
              <br />
              <a
                style={
                  {
                    // textDecoration: "none",
                    // color: "inherit",
                  }
                }
                href="tel:+963432326651"
              >
                + 963 432 326 651
              </a>
            </p>

            <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
          </div>
          <div className="col-sm-6 col-lg-3">
            <img className="img-fluid" src={Logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
