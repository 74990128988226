import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./CourseSection.scss";
import Course from "../Course/Course";
import useCourses from "../../hooks/useCourses";

const CourseSection = () => {
  const [courses] = useCourses();
  const [slickTrackStyle, setSlickTrackStyle] = useState({});

  useEffect(() => {
    // يتحقق من حجم الشاشة عند تحميل الصفحة
    if (window.innerWidth <= 600) {
      // تعيين خاصية transform الافتراضية عند تحميل الصفحة فقط
      setSlickTrackStyle({ transform: "translate3d(0, 0, 0)" });
    }
  }, []);

  const uniqueCoursesMap = {};

  courses.results.forEach((course) => {
    uniqueCoursesMap[course.id] = course;
  });

  const uniqueCourses = Object.values(uniqueCoursesMap);

  const settings = {
    dots: true,
    speed: 500,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="course-section">
      <div className="overlay"></div>
      <div className="container">
        <div className="header-text">
          <h3>الدورات التعليمية المشهورة</h3>
          <p>اكتشف برنامجك المثالي في دوراتنا</p>
        </div>
        <Slider {...settings}>
          {uniqueCourses.map((course) => (
            <div key={course.id}>
              <Course course={course} />
            </div>
          ))}
        </Slider>
      </div>
      <style>
        {`
          .slick-track {
            ${
              window.innerWidth <= 600 ? "transform: translate3d(0, 0, 0);" : ""
            }
          }
        `}
      </style>
    </div>
  );
};

export default CourseSection;
