import React, { useState } from "react";
import "./VerificationSection.scss";

const VerificationSection = () => {
  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState(null);
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    setCertificateId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (certificateId) {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/certeficate/?id=${certificateId}`
        );
        if (!response.ok) {
          throw new Error("خطأ في الاتصال بالـ API");
        }
        const data = await response.json();

        const certificate = data.results.find(
          (cert) => cert.serial_number === certificateId // تأكد من مقارنة الرقم التسلسلي وليس المعرف
        );

        if (certificate) {
          setCertificateData(certificate);
          setMessage(""); // إعادة تعيين الرسالة
        } else {
          setMessage("لم يتم العثور على الشهادة");
          setCertificateData(null);
        }
      } catch (error) {
        setMessage(error.message);
        setCertificateData(null);
      }
    } else {
      setMessage("يرجى إدخال معرف الشهادة");
    }
  };

  return (
    <div className="certificate-verification">
      <div className="overlay"></div>
      <h2>تحقق من الشهادة</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="أدخل معرف الشهادة"
          value={certificateId}
          onChange={handleInputChange}
          required
        />
        <button type="submit">تحقق</button>
      </form>
      {message && <p className="message">{message}</p>}
      {certificateData && (
        <div>
          <p style={{ color: "green" }}>
            تم التحقق من الشهادة: {certificateData.program.title}
          </p>
          <a
            href={certificateData.file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="show-certificate">عرض الشهادة</button>
          </a>
        </div>
      )}
      <p
        className="under-text"
        style={{
          marginTop: "20px",
          width: "60%",
          color: "white",
          textAlign: "center",
          lineHeight: 2.5,
        }}
      >
        نظام التحقق من الشهادات المعتمدة من اكاديمية ISA للتدريب، هو نظام تم
        تطويره خصيصا للتحقق من صحة اعتماد الشهادة الخاصة بأكاديمية ISA والصادرة
        من الأكاديمية اعتبارا من تاريخ 1 إبريل 2024 ، وتعد اي شهادة معتمدة من
        الأكاديمية منذ هذا التاريخ قابلة للتحقق منها ومن صحة اعتمادها من ادارة
        التدريب التابعة لأكاديمية ISA للتدريب من خلال هذه الصفحة، كل المطلوب هو
        البحث في الشهادة عن الرقم الخاص بها (Ref. No)، ثم ضع هذا الرقم في صندوق
        البحث أدناه، واذا كانت الشهادة صحيحة فسيقوم النظام بعرض الشهادة
        وبياناتها، واذا كانت الشهادة غير معتمدة من الأكاديمية فسيظهر لك ذلك.
      </p>
    </div>
  );
};

export default VerificationSection;
