import React, { useEffect, useRef, useState } from "react";
import { createClient } from "agora-rtc-sdk-ng";
import "./LiveCourse.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaExpand, FaCompress } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";

const LiveCourse = () => {
  const { slug } = useParams();
  const channel = slug;
  const localVideoRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [remoteVideoTracks, setRemoteVideoTracks] = useState([]);
  const client = createClient({ mode: "rtc", codec: "vp8" });
  const [appID, setAppID] = useState("");
  const [token, setToken] = useState("");
  const [lesson, setLesson] = useState("");
  const [htmlResponse, setHtmlResponse] = useState("");
  const { user } = useAuth();

  const socket = new WebSocket(
    "wss://admin.internationalskillsacademy.com/ws/livec/"
  );
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const handleKeyup = (event) => {
      if (event.key === "PrintScreen") {
        alert("تسجيل الشاشة غير مسموح!");
      }
    };

    window.addEventListener("keyup", handleKeyup);

    return () => {
      window.removeEventListener("keyup", handleKeyup);
    };
  }, []);

  useEffect(() => {
    const fetchLessonDetails = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/live_courses`
        );
        const lessons = response.data.results;

        const lessonFound = lessons.find((lesson) => lesson.slug === channel);
        if (lessonFound) {
          setLesson(lessonFound);
        } else {
          console.error("Lesson not found for the given slug");
        }
      } catch (error) {
        console.error("Error fetching lesson details: ", error);
      }
    };

    fetchLessonDetails();
  }, [channel]);

  useEffect(() => {
    const fetchAgoraData = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/live/agora/?channelName=${channel}`,
          {
            headers: {
              Authorization: `token ${user.token}`,
            },
          }
        );
        setHtmlResponse(response.data);
      } catch (error) {
        console.error("Error fetching Agora data: ", error);
      }
    };

    fetchAgoraData();
  }, []);

  useEffect(() => {
    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.username && response.message) {
        setMessages((prevMessages) => [...prevMessages, response]);
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/live/token/?channelName=${channel}`
        );
        const data = await response.json();
        setAppID(data.appID);
        setToken(data.token);
      } catch (error) {
        console.error("Error fetching token: ", error);
      }
    };

    fetchToken();
  }, [channel]);

  useEffect(() => {
    const init = async () => {
      if (!appID || !token) return;

      try {
        await client.join(appID, channel, token, null);

        client.on("user-published", async (user, mediaType) => {
          await client.subscribe(user, mediaType);
          if (mediaType === "video") {
            const remoteVideoTrack = user.videoTrack;
            if (remoteVideoTrack) {
              const remoteVideoDiv = document.createElement("div");
              remoteVideoDiv.className = "remote-video";
              remoteVideoTrack.play(remoteVideoDiv);
              setRemoteVideoTracks((prev) => {
                const newTracks = prev.filter((v) => v.key !== user.uid);
                newTracks.push({ key: user.uid, element: remoteVideoDiv });
                return newTracks;
              });
            }
          } else if (mediaType === "audio") {
            const remoteAudioTrack = user.audioTrack;
            if (remoteAudioTrack) {
              remoteAudioTrack.play();
            }
          }
        });

        client.on("user-unpublished", (user) => {
          setRemoteVideoTracks((prev) =>
            prev.filter((el) => el.key !== user.uid)
          );
        });
      } catch (error) {
        console.error("Error joining channel: ", error);
      }
    };

    init();

    return () => {
      client.leave();
    };
  }, [appID, token]);

  const sendMessage = () => {
    if (newMessage.trim()) {
      if (socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          username: lesson.author ? lesson.author.username : "مستخدم غير معروف",
          message: newMessage,
        };
        socket.send(JSON.stringify(messagePayload));
        setNewMessage("");
      } else {
        console.error(
          "WebSocket is not open. Current state: ",
          socket.readyState
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (localVideoRef.current.requestFullscreen) {
        localVideoRef.current.requestFullscreen();
      } else if (localVideoRef.current.mozRequestFullScreen) {
        localVideoRef.current.mozRequestFullScreen(); // Firefox
      } else if (localVideoRef.current.webkitRequestFullscreen) {
        localVideoRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
      } else if (localVideoRef.current.msRequestFullscreen) {
        localVideoRef.current.msRequestFullscreen(); // IE/Edge
      }

      if (remoteVideoTracks.length > 0) {
        remoteVideoTracks.forEach((track) => {
          track.element.play();
        });
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Chrome, Safari and Opera
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {lesson.youtube_link ? (
        <iframe
          style={{
            width: "100%",
            height: "100%",
          }}
          src={
            lesson.youtube_link.includes("embed")
              ? lesson.youtube_link
              : lesson.youtube_link.replace("watch?v=", "embed/")
          }
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <iframe
          style={{
            width: "100%",
            height: "100%",
          }}
          srcDoc={htmlResponse}
        />
      )}
    </div>
  );
};

export default LiveCourse;
