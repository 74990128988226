import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./components/About/About";
import Header from "./components/Header/Header";
import NotFound from "./components/NotFound/NotFound";
import Main from "./components/Main/Main";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import CoursePage from "./components/CoursePage/CoursePage";
import Login from "./components/LoginRegister/Login";
import Register from "./components/LoginRegister/Register";
import AuthProvider from "./contexts/AuthProvider";
import CourseDetails from "./components/CourseDetails/CourseDetails";
import Cart from "./components/Cart/Cart";
import Dashboard from "./components/AdminPanel/Dashboard/Dashboard";
import SyncLoader from "react-spinners/SyncLoader";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { useEffect, useState } from "react";
import JoinUs from "./components/JoinUs/JoinUs";
import Library from "./components/Library/Library";
import LiveCourses from "./components/LiveCourses/LiveCourses";
import CertificatesSection from "./components/CertificatesSection/CertificatesSection";
import ProgramCourses from "./components/ProgramCourses/ProgramCourses";
import LiveCourse from "./components/Live-Course/LiveCourse";
import ConsultingAndStudies from "./components/ConsultingAndStudies/ConsultingAndStudies";
import CategoryPage from "./components/CategoryPage/CategoryPage";
import VerificationSection from "./components/VerificationSection/VerificationSection";
import CHLPage from "./components/CHL-Page/CHL-Page";
import Offers from "./components/Offers/Offers";
import Rules from "./components/Rules/Rules";
import Privacy from "./components/Privacy/Privacy";
import Regulations from "./components/Regulations/Regulations";
import CustomerService from "./components/CustomerService/CustomerService";
import { useLocation } from "react-router-dom";
import VideoLibrary from "./components/Video-Library/Video-Library";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <AuthProvider>
      {loading ? (
        <div className="home-pre-loader">
          <div className="d-flex justify-content-center align-items-center">
            <SyncLoader
              className="syncloader"
              color={"#2f2d52"}
              loading={loading}
              size={20}
            />
          </div>
        </div>
      ) : (
        <Router>
          <MainRouter />
        </Router>
      )}
    </AuthProvider>
  );
}

function MainRouter() {
  const location = useLocation();

  const shouldHideHeaderAndFooter = location.pathname.startsWith(
    "/live-course/"
  );

  return (
    <>
      {!shouldHideHeaderAndFooter && <Header />}
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/home">
          <Main />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/video-library">
          <VideoLibrary />
        </Route>
        <Route path="/live-courses" exact>
          <LiveCourses />
        </Route>
        <Route path="/courses">
          <CoursePage />
        </Route>
        <Route path="/course/:courseId">
          <CourseDetails />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/join-us">
          <JoinUs />
        </Route>
        <Route path="/library">
          <div style={{ marginTop: "200px" }}>
            <Library />
          </div>
        </Route>
        <Route exact path="/" component={CertificatesSection} />
        <Route path="/program-courses/:programId" component={ProgramCourses} />
        <Route path="/category/:slug" component={CategoryPage} />
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/live-course/:slug" exact>
          <LiveCourse />
        </Route>
        <Route path="/Consulting-and-studies">
          <ConsultingAndStudies />
        </Route>
        <Route path="/verification-section">
          <VerificationSection />
        </Route>
        <Route path="/chl-page">
          <CHLPage />
        </Route>
        <Route path="/offers">
          <Offers />
        </Route>
        <Route path="/rules">
          <Rules />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/regulations">
          <Regulations />
        </Route>
        <Route path="/customer-service">
          <CustomerService />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <PrivateRoute path="/cart">
          <Cart />
        </PrivateRoute>
        <PrivateRoute path="/admin">
          <Dashboard />
        </PrivateRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      {!shouldHideHeaderAndFooter && <Footer />}
    </>
  );
}

export default App;
